*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 18px;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  --color-text: #fff;
  --color-bg: #142736;
  --color-link: #00a480;
  --color-link-hover: #fff;
  color: var(--color-text);
  background-color: #142736;
  font-family: "Anonymous Pro", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.lil-gui {
  visibility: hidden;
}

/* Page Loader */
.js {
  .loading::before,
  .loading::after {
    content: "";
    position: fixed;
    z-index: 1000;
  }

  .loading::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #142736;
  }

  .loading::after {
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border-radius: 50%;
    opacity: 0.4;
    background: var(--color-link);
    animation: loaderAnim 0.7s linear infinite alternate forwards;
  }
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;

  &:hover {
    color: var(--color-link-hover);
    outline: none;
  }

  /* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
  &:focus {
    /* Provide a fallback style for browsers
     that don't support :focus-visible */
    outline: none;
    background: lightgrey;
  }

  &:focus:not(:focus-visible) {
    /* Remove the focus indicator on mouse-focus for browsers
     that do support :focus-visible */
    background: transparent;
  }

  &:focus-visible {
    /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
    outline: 2px solid red;
    background: transparent;
  }
}

.unbutton {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  &:focus {
    outline: none;
  }
}

.frame {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 3rem 5vw;
  text-align: center;
  position: relative;
  z-index: 1000;

  .frame__title {
    font-size: 1rem;
    margin: 0 0 1rem;
    font-size: 120%;
    font-weight: bold;
    cursor: pointer;
    a {
      color: #ffffff;
    }
  }

  .frame__services {
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-size: 130%;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;

    .frame__services-title {
      padding-bottom: 1.2rem;
      font-size: 130%;

      span {
        font-weight: 600;
      }

      // &::before {
      //   content: "";
      //   background-color: #ffffff;
      //   width: 15px;
      //   height: 1px;
      //   display: inline-block;
      //   margin-right: 0.6rem;
      // }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding-left: 1rem;
        padding-bottom: 0.38rem;
      }
    }
  }

  .frame__links {
    display: inline;
  }

  .frame__links a:not(:last-child),
  .frame__links-right a:not(:last-child) {
    margin-right: 1rem;
  }

  .frame__links-right {
    margin: 1rem 0;
  }

  .frame__demo--current,
  .frame__demo--current:hover {
    color: var(--color-text);
  }
}

.canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}

.canvas img {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}

@media (max-width: 53em) {
  .canvas {
    display: none;
  }

  .frame {
    .frame__title-wrap {
      .frame__title {
        display: none;
      }
      .logo {
        img {
          width: 80%;
          max-width: 210px;
          margin-top: 0.5rem;
          margin-bottom: 1.3rem;
        }
      }
    }
  }
}

@media screen and (min-width: 53em) {
  .frame {
    position: fixed;
    text-align: left;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 2.5rem 3.35rem;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title ..."
      "... ..."
      "links right";
    .frame__title-wrap {
      grid-area: title;
      .logo {
        display: none;
      }
    }
    .frame__title {
      margin: 0;
    }
    .frame__tagline {
      position: relative;
      margin: 0 0 0 0.25rem;
      padding: 0 0 0 1rem;
      opacity: 0.5;
    }
    .frame__links {
      grid-area: links;
      padding: 0;
      justify-self: start;
    }
    .frame__links-right {
      margin: 0;
      grid-area: right;
      justify-self: end;
      display: flex;
      nav {
        align-self: flex-end;
      }
    }
  }

  .frame a {
    pointer-events: auto;
  }
}

.hidden {
  display: none;
}
