*, :after, :before {
  box-sizing: border-box;
}

:root {
  font-size: 18px;
}

body {
  height: 100vh;
  width: 100vw;
  --color-text: #fff;
  --color-bg: #142736;
  --color-link: #00a480;
  --color-link-hover: #fff;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #142736;
  margin: 0;
  font-family: Anonymous Pro, monospace;
  overflow: hidden;
}

.lil-gui {
  visibility: hidden;
}

.js .loading:before, .js .loading:after {
  content: "";
  z-index: 1000;
  position: fixed;
}

.js .loading:before {
  width: 100%;
  height: 100%;
  background: #142736;
  top: 0;
  left: 0;
}

.js .loading:after {
  width: 60px;
  height: 60px;
  opacity: .4;
  background: var(--color-link);
  border-radius: 50%;
  margin: -30px 0 0 -30px;
  animation: loaderAnim .7s linear infinite alternate forwards;
  top: 50%;
  left: 50%;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

a {
  color: var(--color-link);
  outline: none;
  text-decoration: none;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

a:focus {
  background: #d3d3d3;
  outline: none;
}

a:focus:not(:focus-visible) {
  background: none;
}

a:focus-visible {
  background: none;
  outline: 2px solid red;
}

.unbutton {
  font: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.unbutton:focus {
  outline: none;
}

.frame {
  height: 100vh;
  width: 100vw;
  text-align: center;
  z-index: 1000;
  padding: 3rem 5vw;
  position: relative;
  overflow: hidden;
}

.frame .frame__title {
  cursor: pointer;
  margin: 0 0 1rem;
  font-size: 120%;
  font-weight: bold;
}

.frame .frame__title a {
  color: #fff;
}

.frame .frame__services {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-family: Open Sans, sans-serif;
  font-size: 130%;
  font-weight: 300;
}

.frame .frame__services .frame__services-title {
  padding-bottom: 1.2rem;
  font-size: 130%;
}

.frame .frame__services .frame__services-title span {
  font-weight: 600;
}

.frame .frame__services ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.frame .frame__services ul li {
  padding-bottom: .38rem;
  padding-left: 1rem;
}

.frame .frame__links {
  display: inline;
}

.frame .frame__links a:not(:last-child), .frame .frame__links-right a:not(:last-child) {
  margin-right: 1rem;
}

.frame .frame__links-right {
  margin: 1rem 0;
}

.frame .frame__demo--current, .frame .frame__demo--current:hover {
  color: var(--color-text);
}

.canvas {
  z-index: -1;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.canvas img {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}

@media (max-width: 53em) {
  .canvas, .frame .frame__title-wrap .frame__title {
    display: none;
  }

  .frame .frame__title-wrap .logo img {
    width: 80%;
    max-width: 210px;
    margin-top: .5rem;
    margin-bottom: 1.3rem;
  }
}

@media screen and (min-width: 53em) {
  .frame {
    text-align: left;
    z-index: 100;
    width: 100%;
    max-width: none;
    height: 100vh;
    grid-template: "title ."
                   ". ."
                   "links right"
                   / auto 1fr;
    align-content: space-between;
    padding: 2.5rem 3.35rem;
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
  }

  .frame .frame__title-wrap {
    grid-area: title;
  }

  .frame .frame__title-wrap .logo {
    display: none;
  }

  .frame .frame__title {
    margin: 0;
  }

  .frame .frame__tagline {
    opacity: .5;
    margin: 0 0 0 .25rem;
    padding: 0 0 0 1rem;
    position: relative;
  }

  .frame .frame__links {
    grid-area: links;
    justify-self: start;
    padding: 0;
  }

  .frame .frame__links-right {
    grid-area: right;
    justify-self: end;
    margin: 0;
    display: flex;
  }

  .frame .frame__links-right nav {
    align-self: flex-end;
  }

  .frame a {
    pointer-events: auto;
  }
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.45ee4495.css.map */
